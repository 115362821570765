<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="车辆编号" prop="vehicleId">
                <a-input v-model="queryParam.vehicleId" placeholder="请输入车辆编号或车牌号" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="状态" prop="state">
                <a-select v-model="queryParam.state">
                  <a-select-option value=""> 全部 </a-select-option>
                  <a-select-option value="1"> 待处理 </a-select-option>
                  <a-select-option value="2"> 已处理 </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="生成时间">
                  <a-range-picker
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    :placeholder="['开始日期', '结束日期']"
                    v-model="createTimeRange"
                    @ok="handleCreateTimeRangeOk"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="生成方式" prop="state">
                  <select-filter
                    v-model="queryParam.orderType"
                    place-holder="全部"
                    :labelValues="orderTypeLabelValues"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="处理人" prop="handler">
                  <select-filter
                    v-model="queryParam.handler"
                    place-holder="全部"
                    drop-type="auto"
                    :dataSource="userDataSource"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="完成时间">
                  <a-range-picker
                    style="width: 100%"
                    v-model="daterangeHandleTime"
                    valueFormat="YYYY-MM-DD"
                    format="YYYY-MM-DD"
                    allow-clear
                  />
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
        <div class="table-operations">
          <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['tenant:iot:workOrder:add']">
            <a-icon type="plus" />新增
          </a-button>
          <a-button
            type="primary"
            :disabled="single"
            @click="$refs.createForm.handleUpdate(undefined, ids)"
            v-hasPermi="['tenant:iot:workOrder:edit']"
          >
            <a-icon type="edit" />修改
          </a-button>
          <a-button
            type="danger"
            :disabled="multiple"
            @click="handleDelete"
            v-hasPermi="['tenant:iot:workOrder:remove']"
          >
            <a-icon type="delete" />删除
          </a-button>
          <!-- <a-button type="primary" @click="handleExport" v-hasPermi="['tenant:iot:workOrder:export']"> <a-icon type="download" />导出 </a-button> -->
          <!-- <a-button
            type="dashed"
            shape="circle"
            :loading="loading"
            :style="{ float: 'right' }"
            icon="reload"
            @click="getList"
          /> -->
        </div>
      </a-toolbar>
      <!-- 增加修改 -->
      <work-order-edit ref="workOrderEdit" :stateOptions="stateOptions" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        tid="1"
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
      >
        <span slot="handleTime" slot-scope="text, record">
          {{ parseTime(record.handleTime) }}
        </span>
        <span class="state" slot="state" slot-scope="text, record">
          <span class="state-point" :class="[record.state === '1' ? 'state-point--red' : 'state-point--blue']"></span
          >{{ record.state === '1' ? '待处理' : '已处理' }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" />
          <a @click="$refs.workOrderEdit.handleUpdate(record.id, true)">查看 </a>
          <a-divider type="vertical" />
          <a @click="$refs.workOrderEdit.handleUpdate(record.id, false)">回填 </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { listWorkOrder, delWorkOrder } from '@/api/iot/workOrder'
import WorkOrderEdit from '../ManyVehicleMonitor/modules/WorkOrder'
import { listUser } from '@/api/system/user'
import SelectFilter from '../SelectFilter'
export default {
  name: 'WorkOrder',
  components: {
    WorkOrderEdit,
    SelectFilter
  },
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 处理完成时间时间范围
      daterangeHandleTime: [],
      // 工单状态(字典)：1，待处理；2，处理完成字典
      stateOptions: [],
      // 日期范围
      createTimeRange: [],
      // 查询参数
      queryParam: {
        vehicleId: null,
        warnId: null,
        orderType: undefined,
        handleTime: null,
        state: '',
        handler: undefined,
        createTime: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '车辆编号',
          dataIndex: 'vehicleId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '状态',
          dataIndex: 'state',
          scopedSlots: { customRender: 'state' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '生成方式',
          dataIndex: 'orderType',
          customRender: (text, record, index) => {
            return record.orderType === 1 ? '自动' : record.createBy
          },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '处理人',
          dataIndex: 'handler',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '生成时间',
          dataIndex: 'createTime',
          ellipsis: true,
          align: 'center'
        },

        {
          title: '完成时间',
          dataIndex: 'handleTime',
          ellipsis: true,
          align: 'center'
        },

        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      handlerLabelValues: [],
      orderTypeLabelValues: []
    }
  },
  filters: {},
  created() {
    this.getList()
    this.getDicts('iot_vehicle_order_status').then((response) => {
      this.stateOptions = response.data
    })
    this.getUserList()
  },
  computed: {},
  watch: {},
  methods: {
    getUserList() {
      listUser({}).then((response) => {
        const userLabelValues = []
        const rows = response.rows
        if (rows && rows.length > 0) {
          rows.forEach((p) => {
            userLabelValues.push({
              label: p.userName,
              value: p.userName
            })
          })
        }
        this.orderTypeLabelValues = [
          {
            label: '全部',
            value: ''
          },
          {
            label: '自动',
            value: 1
          },
          ...userLabelValues
        ]
        this.userDataSource = userLabelValues.map((p) => p.value)
      })
    },
    /** 查询车辆工单信息列表 */
    getList() {
      this.loading = true
      this.queryParam.params = {}
      if (
        this.daterangeHandleTime !== null &&
        this.daterangeHandleTime !== '' &&
        this.daterangeHandleTime.length !== 0
      ) {
        this.queryParam.params['beginHandleTime'] = this.daterangeHandleTime[0]
        this.queryParam.params['endHandleTime'] = this.daterangeHandleTime[1]
      }
      listWorkOrder(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    // 工单状态(字典)：1，待处理；2，处理完成字典翻译
    stateFormat(row, column) {
      return this.selectDictLabel(this.stateOptions, row.state)
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.queryParam.createBy = ''
      if (this.queryParam.orderType && this.queryParam.orderType !== 1) {
        this.queryParam.createBy = this.queryParam.orderType
        this.queryParam.orderType = ''
      }
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.daterangeHandleTime = []
      this.queryParam = {
        vehicleId: undefined,
        warnId: undefined,
        orderType: undefined,
        state: undefined,
        handler: undefined,
        createTime: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delWorkOrder(ids).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    }
    /** 导出按钮操作 */
    // handleExport() {
    //   var that = this
    //   this.$confirm({
    //     title: '是否确认导出?',
    //     content: '此操作将导出当前条件下所有数据而非选中数据',
    //     onOk() {
    //       return exportWorkOrder(that.queryParam).then((response) => {
    //         that.download(response.msg)
    //         that.$message.success('导出成功', 3)
    //       })
    //     },
    //     onCancel() {}
    //   })
    // }
  }
}
</script>
<style lang="less" scoped>
.state {
  display: flex;
  justify-content: center;
  align-items: center;
  .state-point {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    margin-right: 2px;
  }

  .state-point--blue {
    background-color: #0e77d1;
  }
  .state-point--red {
    background-color: #b8741a;
  }
}
</style>
