var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-header-wrapper",
    [
      _c(
        "a-card",
        { attrs: { bordered: false } },
        [
          _c(
            "div",
            { staticClass: "table-page-search-wrapper" },
            [
              _c(
                "a-form",
                { attrs: { layout: "inline" } },
                [
                  _c(
                    "a-row",
                    { attrs: { gutter: 48 } },
                    [
                      _c(
                        "a-col",
                        { attrs: { md: 8, sm: 24 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "车辆编号", prop: "vehicleId" } },
                            [
                              _c("a-input", {
                                attrs: {
                                  placeholder: "请输入车辆编号或车牌号",
                                  "allow-clear": "",
                                },
                                model: {
                                  value: _vm.queryParam.vehicleId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryParam, "vehicleId", $$v)
                                  },
                                  expression: "queryParam.vehicleId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { md: 8, sm: 24 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "状态", prop: "state" } },
                            [
                              _c(
                                "a-select",
                                {
                                  model: {
                                    value: _vm.queryParam.state,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.queryParam, "state", $$v)
                                    },
                                    expression: "queryParam.state",
                                  },
                                },
                                [
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "" } },
                                    [_vm._v(" 全部 ")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "1" } },
                                    [_vm._v(" 待处理 ")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "2" } },
                                    [_vm._v(" 已处理 ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.advanced
                        ? [
                            _c(
                              "a-col",
                              { attrs: { md: 8, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  { attrs: { label: "生成时间" } },
                                  [
                                    _c("a-range-picker", {
                                      attrs: {
                                        format: "YYYY-MM-DD",
                                        "value-format": "YYYY-MM-DD",
                                        placeholder: ["开始日期", "结束日期"],
                                      },
                                      on: { ok: _vm.handleCreateTimeRangeOk },
                                      model: {
                                        value: _vm.createTimeRange,
                                        callback: function ($$v) {
                                          _vm.createTimeRange = $$v
                                        },
                                        expression: "createTimeRange",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { md: 8, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: { label: "生成方式", prop: "state" },
                                  },
                                  [
                                    _c("select-filter", {
                                      attrs: {
                                        "place-holder": "全部",
                                        labelValues: _vm.orderTypeLabelValues,
                                      },
                                      model: {
                                        value: _vm.queryParam.orderType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryParam,
                                            "orderType",
                                            $$v
                                          )
                                        },
                                        expression: "queryParam.orderType",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { md: 8, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: { label: "处理人", prop: "handler" },
                                  },
                                  [
                                    _c("select-filter", {
                                      attrs: {
                                        "place-holder": "全部",
                                        "drop-type": "auto",
                                        dataSource: _vm.userDataSource,
                                      },
                                      model: {
                                        value: _vm.queryParam.handler,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryParam,
                                            "handler",
                                            $$v
                                          )
                                        },
                                        expression: "queryParam.handler",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { md: 8, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  { attrs: { label: "完成时间" } },
                                  [
                                    _c("a-range-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        valueFormat: "YYYY-MM-DD",
                                        format: "YYYY-MM-DD",
                                        "allow-clear": "",
                                      },
                                      model: {
                                        value: _vm.daterangeHandleTime,
                                        callback: function ($$v) {
                                          _vm.daterangeHandleTime = $$v
                                        },
                                        expression: "daterangeHandleTime",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      _c(
                        "a-col",
                        { attrs: { md: (!_vm.advanced && 8) || 24, sm: 24 } },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "table-page-search-submitButtons",
                              style:
                                (_vm.advanced && {
                                  float: "right",
                                  overflow: "hidden",
                                }) ||
                                {},
                            },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.handleQuery },
                                },
                                [
                                  _c("a-icon", { attrs: { type: "search" } }),
                                  _vm._v("查询"),
                                ],
                                1
                              ),
                              _c(
                                "a-button",
                                {
                                  staticStyle: { "margin-left": "8px" },
                                  on: { click: _vm.resetQuery },
                                },
                                [
                                  _c("a-icon", { attrs: { type: "redo" } }),
                                  _vm._v("重置"),
                                ],
                                1
                              ),
                              _c(
                                "a",
                                {
                                  staticStyle: { "margin-left": "8px" },
                                  on: { click: _vm.toggleAdvanced },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.advanced ? "收起" : "展开") +
                                      " "
                                  ),
                                  _c("a-icon", {
                                    attrs: {
                                      type: _vm.advanced ? "up" : "down",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-toolbar",
            {
              attrs: { loading: _vm.loading, refresh: "", tid: "1" },
              on: { refreshQuery: _vm.getList },
            },
            [
              _c(
                "div",
                { staticClass: "table-operations" },
                [
                  _c(
                    "a-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["tenant:iot:workOrder:add"],
                          expression: "['tenant:iot:workOrder:add']",
                        },
                      ],
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.$refs.createForm.handleAdd()
                        },
                      },
                    },
                    [
                      _c("a-icon", { attrs: { type: "plus" } }),
                      _vm._v("新增 "),
                    ],
                    1
                  ),
                  _c(
                    "a-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["tenant:iot:workOrder:edit"],
                          expression: "['tenant:iot:workOrder:edit']",
                        },
                      ],
                      attrs: { type: "primary", disabled: _vm.single },
                      on: {
                        click: function ($event) {
                          return _vm.$refs.createForm.handleUpdate(
                            undefined,
                            _vm.ids
                          )
                        },
                      },
                    },
                    [
                      _c("a-icon", { attrs: { type: "edit" } }),
                      _vm._v("修改 "),
                    ],
                    1
                  ),
                  _c(
                    "a-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["tenant:iot:workOrder:remove"],
                          expression: "['tenant:iot:workOrder:remove']",
                        },
                      ],
                      attrs: { type: "danger", disabled: _vm.multiple },
                      on: { click: _vm.handleDelete },
                    },
                    [
                      _c("a-icon", { attrs: { type: "delete" } }),
                      _vm._v("删除 "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c("work-order-edit", {
            ref: "workOrderEdit",
            attrs: { stateOptions: _vm.stateOptions },
            on: { ok: _vm.getList },
          }),
          _c("a-table", {
            attrs: {
              tid: "1",
              loading: _vm.loading,
              size: _vm.tableSize,
              rowKey: "id",
              columns: _vm.columns,
              "data-source": _vm.list,
              pagination: false,
            },
            scopedSlots: _vm._u([
              {
                key: "handleTime",
                fn: function (text, record) {
                  return _c("span", {}, [
                    _vm._v(
                      " " + _vm._s(_vm.parseTime(record.handleTime)) + " "
                    ),
                  ])
                },
              },
              {
                key: "state",
                fn: function (text, record) {
                  return _c("span", { staticClass: "state" }, [
                    _c("span", {
                      staticClass: "state-point",
                      class: [
                        record.state === "1"
                          ? "state-point--red"
                          : "state-point--blue",
                      ],
                    }),
                    _vm._v(
                      _vm._s(record.state === "1" ? "待处理" : "已处理") + " "
                    ),
                  ])
                },
              },
              {
                key: "operation",
                fn: function (text, record) {
                  return _c(
                    "span",
                    {},
                    [
                      _c("a-divider", { attrs: { type: "vertical" } }),
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.$refs.workOrderEdit.handleUpdate(
                                record.id,
                                true
                              )
                            },
                          },
                        },
                        [_vm._v("查看 ")]
                      ),
                      _c("a-divider", { attrs: { type: "vertical" } }),
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.$refs.workOrderEdit.handleUpdate(
                                record.id,
                                false
                              )
                            },
                          },
                        },
                        [_vm._v("回填 ")]
                      ),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
          _c("a-pagination", {
            staticClass: "ant-table-pagination",
            attrs: {
              "show-size-changer": "",
              "show-quick-jumper": "",
              current: _vm.queryParam.pageNum,
              total: _vm.total,
              "page-size": _vm.queryParam.pageSize,
              showTotal: function (total) {
                return "共 " + total + " 条"
              },
            },
            on: {
              showSizeChange: _vm.onShowSizeChange,
              change: _vm.changeSize,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }